import React from 'react';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { redirect } from 'util/redirect';

export type LinkProps = Omit<RouterLinkProps, 'to'> & { to: string };

function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export default React.forwardRef(function Link(
    { to, onClick, ...props }: LinkProps,
    ref: React.Ref<HTMLAnchorElement>
) {
    // This link is important because we want to use redirect to ensure proper navigation,
    // navgiation is done differently to other React Router pages than to Beta pages
    // We add a to as well so that open in a new tab still works.
    const handleClick =
        onClick ||
        ((e) => {
            // We only want to handle left click
            if (!isModifiedEvent(e)) {
                e.preventDefault();
                redirect(to as string);
            }
        });
    return (
        <RouterLink
            {...props}
            ref={ref}
            to={to}
            onClick={handleClick}
        ></RouterLink>
    );
});
