import React from 'react';
import { Ripple } from '@rmwc/ripple';
import themeClassName from 'util/Themes';
import classNames from 'util/classNames';
import { DistributiveOmit } from 'util/types';
import Link, { LinkProps } from 'components/display/Link';
import './Button.scss';

export type ButtonThemes =
    | ''
    | 'action-bar--fullpage'
    | 'action-navigation-primary'
    | 'action-navigation-secondary'
    | 'action-navigation-sub-menu'
    | 'add-targeting-tile'
    | 'combo-button'
    | 'combo-button__left-node'
    | 'combo-button__right-node'
    | 'data-view-toolbar'
    | 'display-as-link'
    | 'flush-flat'
    | 'toolbar-action'
    | 'toolbar-dropdown-action'
    | 'accordion-right'
    | 'accordion'
    | 'big-button'
    | 'split-button-primary'
    | 'split-button-dropdown';

interface BaseButtonProps {
    type?: 'button' | 'submit' | 'reset';
    displayType?: 'action' | 'primary' | 'secondary';
    theme?: ButtonThemes;
    isFlat?: boolean;
    disabled?: boolean;
    tag?: 'button' | 'a';
}

export interface CoreButtonProps
    extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'>,
        Omit<BaseButtonProps, 'tag'> {
    tag?: 'button';
}

export interface CoreButtonLinkProps
    extends DistributiveOmit<LinkProps, 'type'>,
        BaseButtonProps {
    tag: 'a';
}

export type ButtonProps = CoreButtonProps | CoreButtonLinkProps;

function Button(props: CoreButtonProps, ref?: React.Ref<HTMLButtonElement>);
function Button(props: CoreButtonLinkProps, ref?: React.Ref<HTMLAnchorElement>);

function Button(
    {
        tag = 'button',
        type = 'button',
        isFlat = false,
        displayType = 'secondary',
        ...undefaultedProps
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>
) {
    // a bit weird, but we need to leave props as a whole object since it is a discriminated union.  But we default it to button.
    const props = {
        tag,
        type,
        ...undefaultedProps,
    } as DistributiveOmit<ButtonProps, 'isFlat' | 'displayType'>;
    const { className, theme, ...specificProps } = props;
    const { disabled } = specificProps;

    const classes = classNames(
        'button',
        `button--type-${displayType}`,
        className,
        isFlat ? 'button--flat' : 'button--standard',
        disabled && 'button--disabled',
        tag === 'a' && 'button--link-adapter',
        theme && themeClassName(theme)
    );

    if (specificProps.tag === 'a') {
        const { rel, children, type, ...elProps } = specificProps;

        return (
            <Ripple primary>
                <Link
                    ref={ref as React.Ref<HTMLAnchorElement>}
                    className={classes}
                    type={type}
                    {...elProps}
                >
                    <div className='button__content'>{children}</div>
                    <div className='button__background'>{''}</div>
                </Link>
            </Ripple>
        );
    } else {
        const {
            type = 'button',
            children,
            disabled,
            tag: Tag,
            ...elProps
        } = specificProps;

        return (
            <Ripple primary>
                <button
                    ref={ref as React.Ref<HTMLButtonElement>}
                    className={classes}
                    disabled={disabled}
                    type={type}
                    {...elProps}
                >
                    <div className='button__content'>{children}</div>
                    <div className='button__background'>{''}</div>
                </button>
            </Ripple>
        );
    }
}

export default React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    CoreButtonProps | CoreButtonLinkProps
>(Button);
