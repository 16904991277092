import React from 'react';
import CoreButton, {
    ButtonProps as CoreButtonProps,
} from 'components/core/Button';
import Glyph from 'components/display/Glyph';
import { DistributiveOmit } from 'util/types';

export enum BUTTON_TYPE {
    ACTION = 'action',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

type OverriddenTypes = {
    id: string;
    type?: BUTTON_TYPE;
    isFlat?: boolean;
    isEnabled?: boolean;
    isSubmit?: boolean;
    glyphBefore?: string;
    glyphAfter?: string;
    isReadOnly?: boolean;
    innerRef?: React.Ref<HTMLAnchorElement | HTMLButtonElement>;
    onClick?: any;
};

export type ButtonProps = DistributiveOmit<
    CoreButtonProps,
    'type' | 'displayType' | 'onClick'
> &
    OverriddenTypes;

class Button extends React.Component<ButtonProps> {
    static defaultProps = {
        className: '',
        theme: '',
        type: 'secondary',
        isFlat: false,
        isEnabled: true,
        isSubmit: false,
        glyphBefore: '',
        glyphAfter: '',
    };

    render() {
        const {
            className,
            type,
            isSubmit,
            isEnabled,
            isReadOnly,
            innerRef,
            glyphBefore,
            glyphAfter,
            children,
            onClick,

            ...elProps
        } = this.props;

        const classes = ['display-button', className].filter(Boolean).join(' ');

        return (
            <CoreButton
                displayType={type}
                type={isSubmit ? 'submit' : 'button'}
                onClick={isEnabled ? onClick : null}
                disabled={!isEnabled || isReadOnly}
                ref={innerRef}
                className={classes}
                {...(elProps as CoreButtonProps)}
            >
                {glyphBefore && (
                    <Glyph
                        className='button__content__glyph-before'
                        name={glyphBefore}
                    />
                )}
                {children}
                {glyphAfter && (
                    <Glyph
                        className='button__content__glyph-after'
                        name={glyphAfter}
                    />
                )}
            </CoreButton>
        );
    }
}

export default React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ButtonProps
>((props, ref) => <Button innerRef={ref} {...props} />);
