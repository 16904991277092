import React from 'react';
import glyphMap from 'fonts/glyphs/glyphMap.json';
import './Glyph.scss';
import classNames from 'util/classNames';

export type GlyphSizes =
    | ''
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge';

export interface GlyphProps extends React.HTMLProps<HTMLDivElement> {
    iconName: string;
    iconSize?: GlyphSizes;
    theme?: string;
}

const Glyph: React.FC<GlyphProps> = ({
    iconName,
    iconSize,
    theme,
    className,
    ...elProps
}) => {
    const family = glyphMap[iconName];

    const classes = classNames(
        `glyph-core`,
        iconSize && `glyph-core--size-${iconSize}`,
        className,
        `glyph-family-${family}`,
        `glyph-${family}-${iconName}`
    );

    return <div {...elProps} className={classes} />;
};

export default Glyph;
