/**
 * Determines if the current element is overflowing either the x or y axis of its container
 * @param {DOMElement} element
 */
export const isOverflowing = (element) => {
    return (
        element.scrollWidth > element.offsetWidth ||
        element.scrollHeight > element.offsetHeight
    );
};

/**
 * Determines if the current element is overflowing the x axis of its container
 * @param {DOMElement} element
 */
export const isOverflowingX = (element) => {
    return element.scrollWidth > element.offsetWidth;
};

/**
 * Attempts to scale the font size so that the parent element doesn't overflow
 * @param {DOMElement} parentElement
 * @param {DOMElement} targetElement
 */
export const scaleFontSize = (parentElement, targetElement, minFontSize) => {
    const style = window.getComputedStyle(targetElement, null);
    let fontSize = parseInt(style.getPropertyValue('font-size'), 10 /*radix*/);

    while (isOverflowing(parentElement) && fontSize > minFontSize) {
        fontSize--;
        targetElement.style.fontSize = fontSize + 'px';
    }
};
