import { browserHistory } from 'util/browserHistory';

export function isReactRouterPath(relativeUrl) {
    return (
        relativeUrl.startsWith('/view/') || relativeUrl.startsWith('/anacapa/')
    );
}

export function isRelativeUrl(relativeUrl) {
    return relativeUrl.match(/^\/?[A-Za-z0-9_\-~\/]*(#|\?|$)/);
}

// When redirecting, if we are going to another react router route,
// we shoulduse history.push to not cause a refresh,
// but for other pages, we need to trigger a refresh with window.location
export function redirect(relativeUrl: string) {
    if (!isRelativeUrl(relativeUrl)) {
        throw new Error(`Recieved ${relativeUrl} but expected relative url.`);
    }

    if (!isReactRouterPath(relativeUrl)) {
        window.location.href = relativeUrl;
    } else {
        browserHistory.push(relativeUrl);
    }
}
